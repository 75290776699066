import React, { useState, useEffect } from "react";
import Utils from "../../utils/Utils";

const TableCellAdjSQ = ({
  data = null,
  rank = null,
  cellType = null,
  leagueType = null,
  style = {},
  conferenceTeamCount = 0,
  data_name = "",
}) => {
  const [gradientColor, setGradientColor] = useState("");
  let teamCount = 0;
  if (!conferenceTeamCount) {
    if (leagueType === "nba") {
      teamCount = 30;
    } else if (leagueType === "wnba") {
      teamCount = 12;
    } else if (leagueType === "college_mens" || leagueType === "ncaa") {
      teamCount = 358;
    } else if (leagueType === "college_womens" || leagueType === "ncaaw") {
      teamCount = 351;
    }
  }
  const count = conferenceTeamCount || teamCount;
  useEffect(() => {
    setGradientColor(Utils.getConfGradient({ rank: rank, conferenceTeamCount: count }));
  }, [leagueType, rank, conferenceTeamCount, count]);
  return (
    <td data-name={data_name} className="position-relative RankCell" style={style}>
      <div className="d-flex align-items-center justify-content-center gap-1">
        <span
          style={{
            color: cellType === "adjSQ" ? (data > 0 ? "green" : "black") : "",
          }}
        >
          {cellType === "adjSQ" ? (data > 0 ? `+${data?.toFixed(2)}` : `${data?.toFixed(2)}`) : null}
          {cellType !== "adjSQ" ? data?.toFixed(2) : null}
        </span>
        <small style={{ backgroundColor: gradientColor }}>{rank}</small>
      </div>
    </td>
  );
};

export default TableCellAdjSQ;
