import React from "react";
import Image from "next/image";
import MikeMcClure from "../../assets/images/profiles/MikeMcClure.png";
import MonicaSenderos from "../../assets/images/profiles/MonicaSenderos.png";
import KenPom from "../../assets/images/profiles/KenPom.png";
import TimWilliams from "../../assets/images/profiles/TimWilliams.png";
import { CustomCarousel } from "../CustomCarousel";
import { quoteSvg } from "../svg";
function Testimonials() {
  return (
    <section className="testimonials-new">
      <div className="container pt-5 pb-5">
        <h2>TRUSTED BY THE SHARPEST MIND IN THE BASKETBALL</h2>
        <CustomCarousel>
          <div className="review">
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center mb-4">
                <Image src={MikeMcClure.src} width={50} height={50} loading="lazy" alt="Mike McClure Testimonial" />
                <h3>Mike McClure, SPORTSLINE</h3>
              </div>
            </div>
            <span className="quote-svg">{quoteSvg}</span>
            <p>ShotQuality's predictive data is some of the best in sports.</p>
          </div>
          <div className="review">
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center mb-4">
                <Image src={KenPom.src} width={50} height={50} loading="lazy" alt="MONICA SENDEROS Testimonial" />
                <h3> Ken Pomeroy</h3>
              </div>
            </div>
            <span className="quote-svg">{quoteSvg}</span>
            <p>
              ShotQuality evaluates the game based on process instead of results, which in turn outputs valuable data
              and advances the capabilities of predictive basketball analytics.
            </p>
          </div>
          <div className="review">
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center mb-4">
                <Image
                  src="https://pbs.twimg.com/profile_images/1502469232226971655/JJMuAY4N_400x400.jpg"
                  width={50}
                  height={50}
                  loading="lazy"
                  alt="Big Cat Testimonial"
                />
                <h3>Big Cat</h3>
              </div>
            </div>
            <span className="quote-svg">{quoteSvg}</span>
            <p>ShotQualityBets makes it easy to help you find value on the board, and you know I love the board.</p>
          </div>
          {/* <div className="review">
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center mb-4">
                <Image
                  src={MonicaSenderos.src}
                  width={50}
                  height={50}
                  loading="lazy"
                  alt="MONICA SENDEROS Testimonial"
                />
                <h3>MONICA SENDEROS</h3>
              </div>
            </div>
            <span className="quote-svg">{quoteSvg}</span>
            <p>ShotQuality Bets makes it easy to help you find value on the board, and you know I love the board.</p>
          </div>
          <div className="review">
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center mb-4">
                <Image
                  src={TimWilliams.src}
                  width={50}
                  height={50}
                  loading="lazy"
                  alt="Tim 
                Williams Testimonial"
                />
                <h3>Tim Williams</h3>
              </div>
            </div>
            <span className="quote-svg">{quoteSvg}</span>
            <p>ShotQuality Bets makes it easy to help you find value on the board, and you know I love the board.</p>
          </div>
          <div className="review">
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center mb-4">
                <Image
                  src="https://pbs.twimg.com/profile_images/1427790197455007744/rh5vkXid_400x400.jpg"
                  width={50}
                  height={50}
                  loading="lazy"
                  alt="Jordan Majewski Testimonial"
                />
                <h3>Jordan Majewski</h3>
              </div>
            </div>
            <span className="quote-svg">{quoteSvg}</span>
            <p>I truly do find ShotQuality Bets to be the most useful advanced metric service on the market.</p>
          </div> */}
        </CustomCarousel>
      </div>
    </section>
  );
}

export default Testimonials;
