import React from "react";
import Image from "next/image";
import Link from "next/link";
import GreenCheckmark from "../GreenCheckmark/GreenCheckmark";
import DiscordLogo from "../../assets/images/discord_logo.png";
import PlanCard from "../update-plan/PlanCard";

function PlanBlock() {
  return (
    <div className="container pt-5 pb-5 plan-block">
      <div className="row pt-5">
        <div className="col-12 col-md-6">
          <h1>CHOOSE YOUR PLAN TO UNLOCK FULL ACCESS NOW!</h1>
          <div className="d-flex gap-1 mt-4 align-items-center">
            <GreenCheckmark fill="#f26b0b" /> <h5 className="mb-0 w-90">NBA, WNBA, and NCAA Picks and Projections</h5>
          </div>
          <div className="d-flex gap-1 mt-4 align-items-center">
            <GreenCheckmark fill="#f26b0b" /> <h5 className="mb-0 w-90">Access to all tools</h5>
          </div>
          <div className="d-flex gap-1 mt-4 align-items-center">
            <GreenCheckmark fill="#f26b0b" />{" "}
            <h5 className="mb-0 w-90">
              <a href="https://discord.com/invite/shotquality" target="_blank" rel="noopener noreferrer">
                Discord access for early picks <Image src={DiscordLogo.src} height={20} width={25} alt="green tick" />
              </a>
            </h5>
          </div>
          <div className="d-flex gap-1 mt-4 align-items-center">
            <GreenCheckmark fill="#f26b0b" /> <h5 className="mb-0 w-90">Cancel Anytime</h5>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mt-5 d-flex update-plan flex-wrap align-items-center gap-1 justify-content-center">
            <Link href="/register/weekly-premium">
              <PlanCard
                label="7 Days"
                price="137.99"
                period="Every Week"
                type="week"
                isCurrent={false}
                // planValue="weekly-premium"
                planValue={""}
                updateModal="true"
              />
            </Link>
            <Link href="/register/monthly-premium">
              <PlanCard
                label="1 Month"
                price="499.98"
                period="Every Month"
                type="month"
                isCurrent={false}
                // planValue="monthly-premium"
                planValue={""}
                updateModal="true"
              />
            </Link>
            <Link href="/register/yearly-premium">
              <PlanCard
                label="1 Year"
                price="3,999.99"
                period="Every Year"
                type="year"
                isCurrent={false}
                // planValue="yearly-premium"
                planValue={""}
                updateModal="true"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanBlock;
