import React from "react";
import { WeeklyIcon, MonthIcon, YearIcon } from "../../components/svg/index";

const PlanCard = ({ label, price, period, isCurrent, type, onPlanChange, planValue, updateModal }) => {
  const handleClick = (e) => {
    if (!updateModal) {
      console.log("clicked", e.target.value);
      onPlanChange(planValue);
    }
  };

  return (
    <div className={`card ${isCurrent ? "current" : ""} clickable-div`} onClick={handleClick}>
      <div>
        <p>{isCurrent ? "CURRENT PLAN" : ""}</p>
        <div className="icon">{type === "week" ? <WeeklyIcon /> : type === "month" ? <MonthIcon /> : <YearIcon />}</div>
        <h3 className="mt-2">{label}</h3>
        {planValue === "monthly-premium" && <p>$.64 / game</p>}
      </div>
      <div className="d-flex justify-content-center">
        <hr />
      </div>
      <div>
        <h2 className="price">${price}</h2>
        <p>{period}</p>
      </div>
      <div>
        {planValue.includes("premium") && (
          <>
            <p className="text-left d-flex gap-1">
              <span>✅ </span> All Live Games
            </p>
            <p className="text-left d-flex gap-1">
              <span>✅ </span> All predicted ShotQuality scores for NCAA
            </p>
            <p className="text-left d-flex gap-1">
              <span>✅ </span> All historical ShotQuality scores for all leagues
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default PlanCard;
