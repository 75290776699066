import React from "react";

const GreenCheckmark = ({ fill = "#208502" }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill={fill} />
      <path d="M21.8 12L13 20.8L9 16.8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default GreenCheckmark;
