import React, { useState, useEffect } from "react";
import Link from "next/link";
import dayjs from "dayjs";
import Utils, { getOnlyCapLetters } from "../../utils/Utils";
import { useId } from "react";
import StarCheckbox from "../button/StarCheckbox";
import Image from "next/image";
import { getDefYear } from "../../constants/main";
const ScoreCardFuturesGames = ({
  user,
  card,
  isStandardPlan,
  isPremiumPlan,
  index,
  updateMyScoreCenter = "",
  myScoreCenter,
  fromFreeLive = false,
  isToday = false,
  leagueType,
  source = "",
}) => {
  const year = getDefYear(leagueType);
  const [mobileView, setMobileView] = useState("");
  const [homeTeamColor, setHomeTeamColor] = useState("");
  const [awayTeamColor, setAwayTeamColor] = useState("");
  const isPayed = isStandardPlan || isPremiumPlan;
  const checkboxKey = useId();
  const uniqueKey = card.team + card.opponent + "/" + dayjs(card.date).format("YYYY-MM-DD");
  useEffect(() => {
    Utils.readCsv("teams/Color_Code_for_Teams.csv").then((colorCSV) => {
      try {
        setHomeTeamColor(colorCSV.filter((x) => x.Teams === card.team)[0].Colors);
      } catch (e) {
        setHomeTeamColor("orange");
      }
      try {
        setAwayTeamColor(colorCSV.filter((x) => x.Teams === card.opponent)[0].Colors);
      } catch (e) {
        setAwayTeamColor("orange");
      }
    });
  }, []);
  let checkBoxStatus = myScoreCenter?.includes(uniqueKey);
  let teamProjected = "";
  let teamSpread = null;
  let oppTeamSpread = null;
  if (card?.projected_score) {
    teamProjected = (card.projected_opp_score - card.projected_score).toFixed(1);
  }
  if (card?.spread) {
    if (card?.spread > 0) {
      teamSpread = "+" + card?.spread;
      oppTeamSpread = -1 * card?.spread;
    } else {
      teamSpread = card?.spread;
      oppTeamSpread = "+" + -1 * card?.spread;
    }
  }
  return (
    <div className="d-flex flex-column gap-1">
      <div className="gameCard h-auto" onClick={() => setMobileView(card.id)}>
        {updateMyScoreCenter !== "" && (
          <div className="form-check check-box pt-0">
            <StarCheckbox
              checked={checkBoxStatus}
              handleCheck={() => {
                updateMyScoreCenter(uniqueKey);
              }}
              title={checkBoxStatus ? "Added to My ScoreCenter!" : "Add to My ScoreCenter"}
              id={checkboxKey}
            />
          </div>
        )}
        <div className="date-holder text-center">
          <div className="date">
            {isToday ? "Today" : dayjs(card.date).format("MMM DD")} at {card?.time?.replace(":00 ", " ")} EST
          </div>
        </div>
        <div className=" p-0" style={!isPayed && index > 0 ? { filter: "blur(8px)" } : null}>
          <div className="gameCardHeader d-flex justify-content-between" style={{ fontWeight: 700, color: "#6B6C72" }}>
            <div>Home</div>
            <div>Away</div>
          </div>
          <div className="gameCardHeader d-flex justify-content-between" style={{ minHeight: "5.2rem" }}>
            <Link
              href={leagueType === "japan_b1" ? "#" : `/team/${leagueType}/${year}/${card.team}`}
              className="scores-team-name"
            >
              <Image
                src={`http://shotqualitybets.com/static/media/logos/${card.team}.png`}
                alt={`${card.team}`}
                height={40}
                width={40}
                style={{ marginRight: "5px", height: "2.4rem", width: "auto" }}
              />
              {card.team}
            </Link>
            <Link
              href={leagueType === "japan_b1" ? "#" : `/team/${leagueType}/${year}/${card.opponent}`}
              className="scores-team-name"
            >
              <Image
                src={`http://shotqualitybets.com/static/media/logos/${card.opponent}.png`}
                alt={`${card.opponent}`}
                height={40}
                width={40}
                style={{
                  marginLeft: "5px",
                  height: "2.4rem",
                  width: "auto",
                }}
              />
              {card.opponent}
            </Link>
          </div>
          {card?.projected_score && (
            <div className="gameProgress">
              <div
                className="fillProgress"
                style={{
                  backgroundColor: card?.predicted_win_percent < 50 ? awayTeamColor : homeTeamColor,
                  right: card?.predicted_win_percent < 50 ? "0px" : "auto",
                  left: card?.predicted_win_percent < 50 ? "auto" : "0px",
                  width:
                    card?.predicted_win_percent < 50
                      ? `${100 - card?.predicted_win_percent}%`
                      : `${card?.predicted_win_percent}%`,
                }}
              >
                <Image
                  height={24}
                  width={24}
                  src={`http://shotqualitybets.com/static/media/logos/${
                    card?.predicted_win_percent < 50 ? card.opponent : card.team
                  }.png`}
                  alt={`${card?.opponent}`}
                  style={{
                    height: "20px",
                    width: "auto",
                    marginLeft: card?.predicted_win_percent < 50 ? "10px" : "auto",
                    marginRight: card?.predicted_win_percent < 50 ? "auto" : "10px",
                    position: "absolute",
                    right: card?.predicted_win_percent < 50 ? "auto" : "0px",
                    top: "2px",
                  }}
                />
              </div>
            </div>
          )}
          <div className=" d-flex justify-content-between">
            <div>
              {card?.predicted_win_percent ? Math.round(card?.predicted_win_percent) + "%" : null} {teamSpread}
            </div>

            <div>
              {oppTeamSpread} {card?.predicted_win_percent ? Math.round(100 - card?.predicted_win_percent) + "%" : null}
            </div>
          </div>

          <div className="row m-0">
            <div className="col-4 text-left p-0">
              {card?.projected_score && (
                <>
                  {card.team.includes(" ") ? getOnlyCapLetters(card.team) : card.team.substring(0, 4)}{" "}
                  {teamProjected > 0 ? "+" + teamProjected : teamProjected}
                </>
              )}
            </div>
            {card?.total && <div className="col-4 text-center p-0">O/U {card?.total}</div>}
          </div>
          {card?.projected_score && (
            <div className="row m-0">
              <div className="col-4 text-left p-0" style={{ fontWeight: 700 }}>
                {card?.projected_score}
              </div>
              <div className="text-center col-4 p-0">
                Predicted
                <div className="text-center">{(card?.projected_score + card?.projected_opp_score).toFixed(1)}</div>
              </div>
              <div className="col-4 text-right p-0" style={{ fontWeight: 700 }}>
                {card?.projected_opp_score}
              </div>
            </div>
          )}
        </div>
        {source !== "home" && (
          <div
            className="hover-score-team"
            style={
              mobileView === card?.id && window.innerWidth < 820
                ? { display: "flex", justifyContent: "space-evenly" }
                : window.innerWidth >= 820 && mobileView === card.id
                ? { justifyContent: "space-evenly" }
                : { justifyContent: "space-evenly" }
            }
          >
            {isPayed ? (
              <>
                {updateMyScoreCenter !== "" && (
                  <div
                    className="form-check check-box"
                    style={{
                      background: "black",
                      borderRadius: ".8rem",
                      justifyContent: "center",
                      padding: "0.8rem",
                    }}
                  >
                    <StarCheckbox
                      checked={checkBoxStatus}
                      handleCheck={() => {
                        updateMyScoreCenter(uniqueKey);
                      }}
                      title={checkBoxStatus ? "Added to My ScoreCenter!" : "Add to My ScoreCenter"}
                      id={checkboxKey}
                    />
                    <label className="form-check-label" htmlFor={checkboxKey}>
                      {checkBoxStatus ? "Added to My ScoreCenter!" : "Add to My ScoreCenter"}
                    </label>
                  </div>
                )}
                {/* {leagueType === "japan_b1" && (
                  <> */}
                <Link
                  href={`/team/${leagueType}/${year}/${card.team}`}
                  className="scores-team-name-link"
                  target={"_blank"}
                >
                  {card.team}
                </Link>
                <Link
                  href={`/team/${leagueType}/${year}/${card.opponent}`}
                  className="scores-team-name-link"
                  target={"_blank"}
                >
                  {card.opponent}
                </Link>
                <Link
                  href={`/${leagueType}/matchup-output/${card.team}/${card.opponent}/${dayjs(
                    new Date(card.date)
                  ).format("MM DD YYYY")}`}
                  className="scores-team-name-link"
                  target={"_blank"}
                >
                  Game Page
                </Link>
                {/* </>
                )} */}
              </>
            ) : fromFreeLive ? (
              <Link href={`/live?gid=${card?.game_id}`} className="scores-team-name-link">
                ScoreCenter
              </Link>
            ) : (
              <Link href={user ? `/update-plan` : "/login#signup"} className="scores-team-name-link">
                {user ? "Upgrade plan" : "Create an account"}
              </Link>
            )}
          </div>
        )}
      </div>
      {!isPayed && (
        <Link className="btn btn-orange text-center m-auto" href="/welcome">
          Activate Your Membership to Unlock
        </Link>
      )}
    </div>
  );
};

export default ScoreCardFuturesGames;
