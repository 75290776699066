import React, { use } from "react";
import { AppContext } from "../../context/state";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScoreCardFuturesGames from "../ScoreCardFuturesGames/ScoreCardFuturesGames";
import Utils from "../../utils/Utils";
const sortGames = (games) => {
  const now = new Date();
  return games.sort((a, b) => {
    if (a.status === "live" && b.status !== "live") {
      return -1;
    }
    if (a.status !== "live" && b.status === "live") {
      return 1;
    }
    if (a.status === "live" && b.status === "live") {
      const dateA = new Date(a.game_time_nyc);
      const dateB = new Date(b.game_time_nyc);
      return dateA - dateB;
    }

    if (a.status === "final" && b.status !== "final") {
      return 1;
    }
    if (a.status !== "final" && b.status === "final") {
      return -1;
    }
    if (a.status === "final" && b.status === "final") {
      const dateA = new Date(a.game_time_nyc);
      const dateB = new Date(b.game_time_nyc);
      return dateA - dateB;
    }

    const dateA = new Date(a.game_time_nyc);
    const dateB = new Date(b.game_time_nyc);

    return dateA - dateB;
  });
};
function UpcomingMatchups({ user }) {
  const { store, setStore } = useContext(AppContext);
  const { headerLeagueGames } = store;
  const [selectedLeague, setSelectedLeague] = useState("college_mens");
  const [futureGames, setFutureGames] = useState([]);
  const [filteredFutureGames, setFilteredFutureGames] = useState([]);
  const isStandardPlan = Utils.hasStandardPlan(user);
  const isPremiumPlan = Utils.hasPremiumPlan(user) || Utils.isTrader(user);
  useEffect(() => {
    let withFutureGamesTemp = [];
    if (headerLeagueGames) {
      withFutureGamesTemp = Object.values(headerLeagueGames).reduce((acc, league) => {
        return [...acc, ...league];
      }, []);
    }
    const futureGamesTemp = withFutureGamesTemp
      .filter((game) => {
        return game.status === "scheduled" || game.status === "future";
      })
      .map((game) => {
        return {
          ...game,
          id: game.game_id,
          date: game.game_time_nyc,
          time: game.game_time_nyc.split(" ")[1] + " ",
          status: "scheduled",
          projected_opp_score: game.projected_away_score,
          projected_score: game.projected_home_score,
        };
      });
    setFutureGames(futureGamesTemp);
  }, [headerLeagueGames]);
  useEffect(() => {
    if (selectedLeague === "all") {
      setFilteredFutureGames(futureGames.slice(0, 3));
    } else {
      setFilteredFutureGames(futureGames.filter((game) => game.leagueType === selectedLeague).slice(0, 3));
    }
  }, [selectedLeague, futureGames]);
  return (
    <>
      <div className="row mb-5">
        <div className="col-12 col-md-12 text-center mb-4">
          <h2>Upcoming Matchups</h2>
        </div>
        <div className="col-12 col-md-12 text-center">
          <div style={{ background: "#0F131B", borderRadius: "10px" }} className="btn-group w-90">
            <button
              type="button"
              className={`btn btn-lg btn-primary text-white value-finder-buttons-group p-10-15 ${
                selectedLeague === "all" ? "active" : ""
              }`}
              value="all"
              onClick={() => {
                setSelectedLeague("all");
              }}
            >
              All
            </button>
            <button
              type="button"
              className={`btn btn-lg btn-primary text-white value-finder-buttons-group p-10-15 ${
                selectedLeague === "college_mens" ? "active" : ""
              }`}
              value="college_mens"
              onClick={() => {
                setSelectedLeague("college_mens");
              }}
            >
              NCAA
            </button>
            <button
              type="button"
              className={`btn btn-lg btn-primary text-white value-finder-buttons-group p-10-15 ${
                selectedLeague === "nba" ? "active" : ""
              }`}
              value="nba"
              onClick={() => {
                setSelectedLeague("nba");
              }}
            >
              NBA
            </button>

            <button
              type="button"
              className={`btn btn-lg btn-primary text-white value-finder-buttons-group p-10-15 ${
                selectedLeague === "wnba" ? "active" : ""
              }`}
              value="wnba"
              onClick={() => {
                setSelectedLeague("wnba");
              }}
            >
              WNBA
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ">
          <div className="gameCardList">
            {filteredFutureGames.length > 0 &&
              filteredFutureGames.map((card) => {
                const league =
                  card.leagueType == "college_mens"
                    ? "ncaa"
                    : card.leagueType == "college_womens"
                    ? "ncaaw"
                    : card.leagueType;
                return (
                  <ScoreCardFuturesGames
                    key={card.id}
                    card={card}
                    isStandardPlan={isStandardPlan}
                    isPremiumPlan={isPremiumPlan}
                    user={user}
                    leagueType={league}
                    source="home"
                  />
                );
              })}
          </div>
        </div>
        <div className="col-12 ">
          {filteredFutureGames.length === 0 && (
            <div className="text-center">
              <h4>No upcoming games</h4>
            </div>
          )}
        </div>
        <div className="col-12 text-center fs-4 mt-5">
          ShotQuality live, pre-game, and post game data powers winning picks and predictions for every NCAA, NBA, and
          WNBA game. Activate your account and win more.
        </div>
      </div>
    </>
  );
}

export default UpcomingMatchups;
