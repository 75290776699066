/* eslint-disable jsx-a11y/accessible-emoji */
import { useState, useEffect, useRef, useContext } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import Loader from "react-loader-spinner";
import Link from "next/link";
import homePageFirstBlock from "../assets/images/homePageFirstBlock.png";
import nbaLiveBanner from "../assets/images/NBA_live_banner.gif";
// import is_back from "../assets/images/is_back.png";
import win_more_with_data from "../assets/images/win_more_with_data.png";
import shot_tracked_full from "../assets/images/shot_tracked_full.gif";
import Macbook from "../assets/images/Macbook.png";
import tablesNew from "../assets/images/tablesNew.png";
import model from "../assets/images/model.png";
import trends from "../assets/images/trends.png";
import MainLayout from "../components/MainLayout/MainLayout";
import { appServerSideProps } from "../utils/getAppService";
import useWindowResize from "../components/hooks/useWindowResize";
import { AppContext } from "../context/state";
import useInfiniteScroll from "../components/hooks/useInfiniteScroll";
import TableCellAdjSQ from "../components/table/TableCellAdjSQ";
import TableCellRank from "../components/table/TableCellRank";
import { capitalizeFirstLetter } from "../utils/Utils";
import { TableHeader } from "../components/table/TableHeader";
import dayjs from "dayjs";
import transformTime from "./[league]/linevalue-welcome/utils/transformTime";
import { AiOutlineArrowRight } from "react-icons/ai";
import { leagueData } from "../components/header/Header";
import ContentfulBlogs from "../components/contentfulBlogs/ContentfulBlogs";
import { validateEmail } from "../utils/Utils";
import Utils from "../utils/Utils";
import advancedFormat from "dayjs/plugin/advancedFormat";
import Head from "next/head";
import WelcomeScoreCards from "../components/WelcomeScoreCards";
import { quoteSvg } from "../components/svg";
import PriceBlock from "../components/PriceBlock";
import Testimonials from "../components/testimonials";
import PlanBlock from "../components/PlanBlock";
import UpcomingMatchups from "../components/home/UpcomingMatchups";
import { teamStatsHeader, miscTableHeader, getDefYear } from "../constants/main";

dayjs.extend(advancedFormat);

const MatchupTeamStats = dynamic(() => import("../components/home/MatchupTeamStats"), { ssr: false });
import { getBookLogo } from "./[league]/best-bets";
const leagues = Object.keys(leagueData);

const HomePage = ({ userDetails = null }) => {
  const defaultLeague = "ncaa";

  const tableRef = useRef();
  const teamStandingRef = useRef();
  const [asc] = useState(true);
  const [teamData, setTeamData] = useState([]);

  const [filteredTeamData, setFilteredTeamData] = useState([]);
  const windowSize = useWindowResize();
  const { store, setStore } = useContext(AppContext);
  const [sortedKey, setSortedKey] = useState("");
  const [formError, setFormError] = useState();
  const [email, setEmail] = useState();
  const [formSuccess, setFormSuccess] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [year, setYear] = useState(getDefYear(defaultLeague));
  const [filtered, setFiltered] = useState([]);
  const [rawTeams, setRawTeams] = useState([]);
  const [teamStandingsSortedKey, setTeamStandingsSortedKey] = useState("");
  const [coachData, setCoachData] = useState({
    confName: "",
    teams: [],
    isDisplayed: false,
  });
  const isTrader = Utils.isTrader(userDetails);

  const [slicedArray] = useInfiniteScroll({
    array: filteredTeamData,
    scrollType: windowSize?.["width"] > 768 ? "window" : "parent",
    parentRef: tableRef,
    itemsPerView: 35,
  });
  const [teamStandingsSlicedArray] = useInfiniteScroll({
    array: filtered,
    scrollType: windowSize?.["width"] > 768 ? "window" : "parent",
    parentRef: teamStandingRef,
    itemsPerView: 10,
  });

  const getDynamicRanks = (arr = []) => {
    const getTeamRank = (teamName, sortedArr) => {
      return sortedArr.findIndex((elem) => elem["Team_Name"] === teamName) + 1;
    };

    let sortedOffensive = [...arr.sort(Utils.dynamicsort("Offensive_Shot_Quality", "desc"))];
    let sortedDefensive = [...arr.sort(Utils.dynamicsort("Defensive_Shot_Quality", "asc"))];
    let sortedRimAndRate3 = [...arr.sort(Utils.dynamicsort("rim_and_3_rate", "desc"))];

    let sortedAndRankedResponse = arr.sort(Utils.dynamicsort("Adjusted_Shot_Quality", "desc")).map((elem, index) => {
      return {
        ...elem,

        adjSQRank: index + 1,
        offensiveRank: getTeamRank(elem["Team_Name"], sortedOffensive),
        defensiveRank: getTeamRank(elem["Team_Name"], sortedDefensive),
        rimAndRate3Rank: getTeamRank(elem["Team_Name"], sortedRimAndRate3),
      };
    });

    return sortedAndRankedResponse;
  };

  useEffect(() => {
    setCoachData({
      confName: "",
      teams: [],
      isDisplayed: false,
    });
  }, [store, year, rawTeams]);

  useEffect(() => {
    setStore({ ...store, leagueType: defaultLeague });
    leagues.forEach((league) => {
      fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/${league}/value-finder/games/hompage`)
        .then((response) => response.json())
        .then((response) => {
          if (response?.data[0]) {
            let game = response.data[0];
            let gameWithLeague = { ...game, league: league };
            setTeamData((previous) => [...previous, gameWithLeague]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/${
        defaultLeague === "ncaa" ? "college_mens" : defaultLeague === "ncaaw" ? "college_womens" : defaultLeague
      }/${year}/team-standings`
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (!response) {
          let tempData = {
            data: [
              {
                Actual_Record: "N/A",
                Actual_Win_Percentage: 0.0,
                Conference: NaN,
                Defensive_Shot_Quality: 0.0,
                Defensive_Shot_Quality_Rank_Visual: NaN,
                Offensive_Shot_Quality: 0,
                Offensive_Shot_Quality_Rank_Visual: NaN,
                Record_Luck: 0.0,
                SQ_Record: "N/A",
                NET_SQ: 0,
                SQ_Win_Percentage: 0.0,
                Team_Name: NaN,
                Adjusted_Shot_Quality: 0.0,
              },
            ],
          };
          return tempData;
        }
        return response.data;
      })
      .then((response) => {
        sessionStorage.setItem(`teamStandingsCount${year}`, response.length);

        return response.map((dt) => {
          return {
            ...dt,
            Adjusted_Shot_Quality: Number.parseFloat(dt["NET_SQ"].toFixed(2)),
          };
        });
      })
      .then((response) => {
        let sortedAndRankedResponse = getDynamicRanks(response);
        const sessionObj = {};
        sessionObj[year] = sortedAndRankedResponse;
        sessionStorage.setItem("TeamStandings", JSON.stringify(sessionObj));
        setTeamStandingsSortedKey("Adjusted_Shot_Quality");
        setRawTeams(sortedAndRankedResponse);
        setFiltered(sortedAndRankedResponse.slice(0, 10));
      })
      .catch(async (err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (teamData.length > 0) {
      setFilteredTeamData(teamData);
    }
  }, [teamData]);

  const addUpTeamScore = (teamAScore, teamBScore) => {
    if (teamAScore > teamBScore) {
      return teamAScore + teamBScore;
    } else {
      return teamBScore + teamAScore;
    }
  };

  const calculateScore = (teamAScore, teamBScore) => {
    if (teamAScore > teamBScore) {
      return teamAScore - teamBScore;
    } else {
      return teamBScore - teamAScore;
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmitClick = async (e) => {
    setFormError();
    e.preventDefault();
    console.log("emaasdfil", email);
    if (!email || email == "") {
      setFormError("Please enter an email");
      return;
    }
    if (!validateEmail(email)) {
      setFormError("Email is not valid");
      return;
    }
    console.log("email", email);
    fetch(`${process.env.NEXT_PUBLIC_API_URL_API_URL}/api/klaviyo/home/${email}/save`)
      .then((res) => {
        if (res.status === 200) {
          setButtonDisabled(true);
          setFormSuccess("Request has been sent successfully!");
          setFormError("");
        } else {
          setFormError("An error occurred. Please try again");
        }
      })
      .catch((err) => {
        console.error("err", err);
        setFormError("An error occurred. Please try again");
      });
  };

  return (
    <MainLayout>
      <Head>
        <title>
          Top {defaultLeague.toUpperCase()} Basketball Bets Today - Expert Picks, Betting Odds & Predictions |
          ShotQualityBets
        </title>
        <meta
          name="description"
          content={`Unlock the best ${defaultLeague.toUpperCase()} basketball bets and predictions for today's games. Dive into in-depth analysis, betting trends, and expert picks to enhance your betting strategy. Stay ahead of the game with ShotQualityBets' latest odds and insights.`}
        />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="/logo.png" />
        <meta property="og:url" content="" />
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ShotQualityBets" />
      </Head>

      <main id="ContentBox scrollbar-none">
        {!isTrader && 1 == 2 && (
          <div className="homepage-container pt-5 pb-5 d-flex align-items-center black-blue-gradient">
            <div className="container ">
              <div className="row">
                <div className="col-md-7 col-12">
                  <h1 className="color-orange">🏀 THE BEST DATA IN BASKETBALL</h1>
                  <br />
                  <h2 className="text-white mt-3">GET FREE PICKS DELIVERED TO YOUR INBOX.</h2>
                  <form
                    action="#"
                    className="pt-3"
                    style={{
                      display: "flex",
                      gap: "2rem",
                      alignItems: "start",
                      flexWrap: "wrap",
                    }}
                  >
                    <input
                      type="email"
                      placeholder="Enter your Email"
                      className="input outline"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      style={{ minWidth: "20rem" }}
                      onChange={handleEmailChange}
                      value={email}
                    />
                    <button className="btn btn-orange p-10-15" onClick={onSubmitClick} disabled={buttonDisabled}>
                      SUBMIT
                    </button>
                  </form>
                  <div className="position-relative w-100">
                    {formError ? <p className={"wrongDetails"}>{formError}</p> : <></>}
                    {formSuccess ? <p className={"formSuccess"}>{formSuccess}</p> : <></>}
                  </div>
                </div>
              </div>

              {teamData.length > 0 && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="MiscStats ValueBracket best-bets">
                      <div className="heading-container">
                        <h2>🏆 TODAY'S BEST BET</h2>
                        <a
                          href={`${
                            slicedArray[0]?.league === "college_mens" ? "/ncaa" : "/" + slicedArray[0]?.league
                          }/best-bets`}
                          style={{ color: "#f26b0b" }}
                        >
                          <strong>
                            See More Matchups <AiOutlineArrowRight style={{ fontWeight: "900", fontSize: "1.3rem" }} />
                          </strong>
                        </a>
                      </div>
                      <section className="page-content">
                        <div className="">
                          <p>
                            Daily, our advanced algorithms forecast the anticipated scores for all{" "}
                            {defaultLeague.toUpperCase()} basketball contests.
                          </p>
                          <div className="table-responsive low-padding table-space" style={{ overflow: "auto" }}>
                            {filteredTeamData.length === 0 ? (
                              <div style={{}}>
                                <h4>
                                  Looks like there are no projections for today's {store.leagueType.toUpperCase()}{" "}
                                  scheduled basketball games.
                                </h4>{" "}
                              </div>
                            ) : (
                              <table className="table tl-fixed-desktop" style={{ position: "relative" }}>
                                <thead className="sticky-th-2">
                                  <tr className="text-center header-container-table mobile-view-hide">
                                    <TableHeader
                                      headers={miscTableHeader}
                                      onSortClick={() => {
                                        console.log(" sorting");
                                      }}
                                      ascOrder={asc ? "asc" : "desc"}
                                      sortedKey={sortedKey}
                                    />
                                  </tr>
                                </thead>

                                <tbody>
                                  {slicedArray.map((team, index) => {
                                    if (team.is_spread || !team.is_spread) {
                                      return (
                                        <tr
                                          className="mobile-view-show best-bets"
                                          key={`${team["Date"]}_${team["Time"]}_${team["Home"]}_${team["Away"]}_${index}`}
                                        >
                                          <td
                                            className="date-time"
                                            style={{
                                              textAlign: "center",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            {`${dayjs(team.Date).format("MMM. Do")} ${
                                              team["Time"].length > 8 ? transformTime(team["Time"]) : team["Time"]
                                            } ET`}
                                          </td>
                                          <td className="home-away-team">
                                            <Image
                                              src={`http://shotqualitybets.com/static/media/logos/${team["Home"]}.png`}
                                              width={30}
                                              height={30}
                                              loading="lazy"
                                              alt={"home-away-team"}
                                              className="img-fluid w-25 pull-left GameCard__team--logo logo-left2"
                                            />
                                            &nbsp; {team["Home"]} {"  "}
                                            <b>vs</b> {"  "}
                                            <Image
                                              src={`http://shotqualitybets.com/static/media/logos/${team["Away"]}.png`}
                                              alt={"home-away-team"}
                                              loading="lazy"
                                              width={30}
                                              height={30}
                                              className="img-fluid w-25  GameCard__team--logo"
                                            />
                                            &nbsp; {team["Away"]}
                                          </td>
                                          <td className="projected-winner">
                                            <span className="mobile-header">
                                              <b>ShotQuality Projection</b>
                                            </span>
                                            <span>
                                              {" "}
                                              <Image
                                                src={`http://shotqualitybets.com/static/media/logos/${team["Projected Winner"]}.png`}
                                                alt={"home-away-team"}
                                                className="img-fluid w-25  GameCard__team--logo"
                                                width={30}
                                                loading="lazy"
                                                height={30}
                                              />{" "}
                                              <b>{` ${team["Projected Winner"]}`}</b> wins{" "}
                                              <b>{`${
                                                team["Projected Winner"] === team["Home"]
                                                  ? team["Projected Home Score"]
                                                  : team["Projected Away Score"]
                                              }`}</b>{" "}
                                              <svg
                                                width="7"
                                                height="8"
                                                viewBox="0 0 7 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M0.500001 4.86603C-0.166666 4.48113 -0.166667 3.51887 0.499999 3.13397L5 0.535898C5.66667 0.150998 6.5 0.632123 6.5 1.40192L6.5 6.59808C6.5 7.36788 5.66667 7.849 5 7.4641L0.500001 4.86603Z"
                                                  fill="#F26B0B"
                                                />
                                              </svg>{" "}
                                              <b>
                                                {`${
                                                  team["Projected Winner"] === team["Home"]
                                                    ? team["Projected Away Score"]
                                                    : team["Projected Home Score"]
                                                }`}
                                              </b>
                                            </span>
                                            <br />
                                            <span>
                                              Total Score:{" "}
                                              <b>{` ${addUpTeamScore(
                                                team["Projected Home Score"],
                                                team["Projected Away Score"]
                                              ).toFixed(1)}`}</b>
                                            </span>
                                            <br />
                                            <span>
                                              Difference: <b>{`${team["Projected Winner"]}`}</b> wins by{" "}
                                              <b>{`${calculateScore(
                                                team["Projected Home Score"],
                                                team["Projected Away Score"]
                                              ).toFixed(2)}`}</b>
                                            </span>
                                            <br />
                                            <span>
                                              Points of Value: <b>{`${team["Points of Value"]}`}</b>
                                            </span>
                                            <br />
                                          </td>
                                          <td
                                            className="side-with-value"
                                            style={{
                                              textAlign: "center",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            <span className="mobile-header" style={{ marginBottom: "10px !important" }}>
                                              <b>Plays</b>
                                            </span>
                                            <p className="mm-best-bets-tag" style={{ display: "inline" }}>
                                              {`${
                                                team["Side with Value"]
                                                  ? capitalizeFirstLetter(team["Side with Value"])
                                                  : ""
                                              }`}{" "}
                                              {team["Side with Value"] === team["Away"]
                                                ? team[`${team["Best"]}`] * -1
                                                : team[`${team["Best"]}`]}
                                            </p>{" "}
                                            <p style={{ display: "inline" }}>at </p>
                                            {team.Best === "BetMGM" ? (
                                              <p style={{ display: "inline" }}>
                                                <Link
                                                  style={{ display: "inline" }}
                                                  href={`https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1686386`}
                                                >
                                                  <Image
                                                    alt="BetMGM"
                                                    style={{ marginLeft: "0px" }}
                                                    width={30}
                                                    height={30}
                                                    loading="lazy"
                                                    className="mm-booking-logo"
                                                    src={getBookLogo(team["Best"])}
                                                  />
                                                </Link>
                                              </p>
                                            ) : team.Best === "PointsBet" ? (
                                              <p style={{ display: "inline" }}>
                                                <Link
                                                  style={{ display: "inline" }}
                                                  href={`https://record.pointsbetpartners.com/_k-IlDShwbNtPLtADCBD7VGNd7ZgqdRLk/8016/`}
                                                >
                                                  <Image
                                                    alt="PointsBet"
                                                    style={{ marginLeft: "0px" }}
                                                    width={30}
                                                    height={30}
                                                    loading="lazy"
                                                    className="mm-booking-logo"
                                                    src={getBookLogo(team["Best"])}
                                                  />
                                                </Link>
                                              </p>
                                            ) : (
                                              <p style={{ display: "inline" }}>
                                                <Image
                                                  alt={"Best Bet"}
                                                  style={{ marginLeft: "0px" }}
                                                  className="mm-booking-logo"
                                                  src={getBookLogo(team["Best"])}
                                                  width={30}
                                                  height={30}
                                                />
                                              </p>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}

                                  {!slicedArray.length & userDetails ? (
                                    <div className="loader mt-5">
                                      <Loader type={"Oval"} visible color={"orange"} height={60} width={60} />
                                    </div>
                                  ) : null}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="black-blue-gradient container-fluid pt-4 mt-1">
          <div className="row">
            <div className="col-md-6 col-12 p-0">
              {/* <h1 className="text-white font-4 fw-5" style={{ transform: "scale(1, 1.4)" }}>
                THE BEST DATA IN BASKETBALL
              </h1>
              <br />
              <h2 className="text-black text-shadow-orange font-7 fw-9 mt-3" style={{ transform: "scale(1, 1.4)" }}>
                IS BACK
              </h2> */}
              <Image
                src={win_more_with_data}
                style={{ height: "max-content", maxHeight: "33rem", minHeight: "20rem" }}
                height={1}
                width={1}
                layout="responsive"
                priority={true}
                alt={`Comprehensive ${defaultLeague.toUpperCase()} Basketball Picks and Predictions Chart with Analytics and Trend Lines`}
              />
              <Link
                href={isTrader ? `/${defaultLeague}/shotquality-scores` : `/welcome`}
                className="btn btn-orange b-radius-4 insights"
              >
                {defaultLeague.toUpperCase()} INSIGHTS
              </Link>
            </div>
            <div className="col-md-6 col-12 p-0">
              <Image
                src={shot_tracked_full}
                style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
                height={1}
                width={1}
                // layout="responsive"
                priority={true}
                alt="Comprehensive NBA Basketball Picks and Predictions Chart with Analytics and Trend Lines"
              />
            </div>
          </div>
        </div>
        <div className="homePage container">
          {/* <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h1>
                🏀 Power your NBA{" "}
                <span
                  style={{
                    fontFamily: "var(--font-oswald)",
                    fontWeight: 700,
                    color: "#EF6850",
                  }}
                >
                  Forecasts, Picks, and Predictions
                </span>{" "}
                with the best data in basketball.
              </h1>
              <p className="mt-4">
                ShotQualityBets is the only place where you can find the most accurate NBA basketball picks and
                predictions. Our proprietary algorithms analyze every NBA basketball game and provide you with the best
                data in the game to sharpen your edge. <Link href="/welcome"> Activate your membership </Link> and
                unlock 🔓 access to NBA Predictions and live scores for NBA, NCAA, and WNBA basketball games.
              </p>
            </div>
            <div className="col-md-12 text-center">
              <Image
                src={nbaLiveBanner}
                style={{ height: "max-content" }}
                width={625}
                height={529}
                priority={true}
                alt="Comprehensive NBA Basketball Picks and Predictions Chart with Analytics and Trend Lines"
              />
            </div>
          </div> */}
          <div className="mb-5 Scoreside">
            <UpcomingMatchups user={userDetails} />
          </div>
          <div className="mt-0">{!isTrader && <PriceBlock />}</div>
          {!isTrader && (
            <div className="mt-5 pt-4 pb-4 box-shadow-1">
              <h4 className="text-center fw-4">
                The ShotQuality FREE Pick of the Day is your daily dose of winning data.
              </h4>
              <form action="#" className="pt-3 justify-content-center align-items-center d-flex flex-wrap gap-2">
                <input
                  type="email"
                  placeholder="Enter your Email"
                  className="input text-center transparent text-black"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  style={{ minWidth: "20rem" }}
                  onChange={handleEmailChange}
                  value={email}
                />
                <button className="btn btn-orange p-10-15" onClick={onSubmitClick} disabled={buttonDisabled}>
                  Get the POD
                </button>
              </form>
              <div className="position-relative w-100 text-center">
                {formError ? <p className={"wrongDetails"}>{formError}</p> : <></>}
                {formSuccess ? <p className={"formSuccess"}>{formSuccess}</p> : <></>}
              </div>
            </div>
          )}
          <div className="mt-5">
            <WelcomeScoreCards />
          </div>

          {teamData.length > 0 && (
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="heading-container">
                  <h2>Today's Free {defaultLeague.toUpperCase()} Expert Basketball Game Prediction</h2>
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                  {teamData.length > 0 &&
                    teamData.map((game) => {
                      return (
                        <div className="col-md-4 col-lg-4 second" key={game.Away + game.Home}>
                          <MatchupTeamStats opponentName={game.Away} teamName={game.Home} league={game.league} />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          {filtered.length > 0 && (
            <div className="row pb-4">
              <div className="col-md-12">
                <div className="MiscStats ValueBracket best-bets">
                  <div className="heading-container">
                    <h2>{defaultLeague.toUpperCase()} Team Standings</h2>
                    <a href={`/${defaultLeague}/team-standings`} style={{ color: "#f26b0b" }}>
                      <strong>
                        See Full {defaultLeague.toUpperCase()} Conference Standings{" "}
                        <AiOutlineArrowRight style={{ fontWeight: "900", fontSize: "1.3rem" }} />
                      </strong>
                    </a>
                  </div>
                  <section className="page-content">
                    {teamStandingsSlicedArray.length > 0 ? (
                      <div className="table-responsive responsive-to-card low-padding table-space">
                        <table className="table tl-fixed-desktop position-relative">
                          <thead style={{ zIndex: 9, background: "none" }}>
                            <tr>
                              {teamStandingsSlicedArray.length > 0 ? (
                                <TableHeader
                                  headers={
                                    defaultLeague === "wnba"
                                      ? teamStatsHeader.filter((it) => it.key !== "Conference")
                                      : teamStatsHeader
                                  }
                                  onSortClick={() => {}}
                                  ascOrder={true}
                                  sortedKey={teamStandingsSortedKey}
                                />
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {teamStandingsSlicedArray.map((data, i) => {
                              const rankConfig = {
                                leagueType: coachData["isDisplayed"] ? "conference" : store.leagueType,
                                conferenceTeamCount: coachData["isDisplayed"] ? coachData["teams"].length : 0,
                              };
                              return (
                                <tr key={"teamsconf" + i}>
                                  <td
                                    style={{
                                      //textAlign: "left",
                                      verticalAlign: "middle",
                                      fontFamily: "var(--font-inter)",
                                      fontSize: "14px",
                                      fontWeight: 700,
                                      color: "#0F131B",
                                    }}
                                    title={data[teamStatsHeader[0].key] ?? ""}
                                    data-name={teamStatsHeader[0].title}
                                  >
                                    {data[teamStatsHeader[0].key] ? (
                                      data[teamStatsHeader[5].key] === "0 - 0" ? (
                                        data[teamStatsHeader[0].key]
                                      ) : (
                                        <Link
                                          href={`/team/${defaultLeague}/${year}/${data[teamStatsHeader[0].key]}`}
                                          target={"_blank"}
                                        >
                                          {data[teamStatsHeader[0].key]}
                                        </Link>
                                      )
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                  {store.leagueType !== "wnba" && (
                                    <td title={data[teamStatsHeader[1].key]} data-name={teamStatsHeader[1].title}>
                                      <Link href={`/${defaultLeague}/team-standings/${data[teamStatsHeader[1].key]} `}>
                                        {data[teamStatsHeader[1].key] ? (
                                          data[teamStatsHeader[5].key] === "0 - 0" ? (
                                            data[teamStatsHeader[1].key]
                                          ) : (
                                            <p style={{ fontSize: "15px", marginBottom: 0 }}>
                                              {data[teamStatsHeader[1].key].length > 13
                                                ? data[teamStatsHeader[1].key].slice(0, 12).concat("...")
                                                : data[teamStatsHeader[1].key]}
                                            </p>
                                          )
                                        ) : (
                                          "N/A"
                                        )}
                                      </Link>
                                    </td>
                                  )}

                                  <TableCellAdjSQ
                                    data={data[teamStatsHeader[2].key]}
                                    rank={data["adjSQRank"]}
                                    cellType="adjSQ"
                                    {...rankConfig}
                                    data_name={teamStatsHeader[2].title}
                                  />

                                  <TableCellRank
                                    data_name={teamStatsHeader[3].title}
                                    rankValue={data["offensiveRank"]}
                                    {...rankConfig}
                                  >
                                    {data[teamStatsHeader[3].key]}
                                  </TableCellRank>
                                  <TableCellRank
                                    data_name={teamStatsHeader[4].title}
                                    rankValue={data["defensiveRank"]}
                                    leagueType={store.leagueType}
                                  >
                                    {data[teamStatsHeader[4].key]}
                                  </TableCellRank>

                                  <TableCellRank
                                    data_name={teamStatsHeader[5].title}
                                    rankValue={data["rimAndRate3Rank"]}
                                    {...rankConfig}
                                  >
                                    {Utils.toPercent(data[teamStatsHeader[5].key])}
                                  </TableCellRank>
                                  <td data-name={teamStatsHeader[6].title}>{data[teamStatsHeader[6].key]}</td>
                                  <td data-name={teamStatsHeader[7].title}>{data[teamStatsHeader[7].key]}</td>
                                  <td data-name={teamStatsHeader[8].title}>
                                    {Utils.toPercent(data[teamStatsHeader[8].key])}
                                  </td>
                                  <td data-name={teamStatsHeader[9].title}>
                                    {Utils.toPercent(data[teamStatsHeader[9].key])}
                                  </td>
                                  {data[teamStatsHeader[10].key] > 0 ? (
                                    <td data-name={teamStatsHeader[10].title} style={{ color: "green" }}>
                                      +{data[teamStatsHeader[10].key].toFixed(2)}
                                    </td>
                                  ) : (
                                    <td data-name={teamStatsHeader[10].title} style={{ color: "red" }}>
                                      {data[teamStatsHeader[10].key].toFixed(2)}
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <p>
                          ShotQuality data measure the expected performance of every {defaultLeague.toUpperCase()}{" "}
                          basketball team which allows you to manage to process over results.{" "}
                          <Link href="/glossary/Adjusted%20ShotQuality%20(adj%20SQ)">
                            Learn more about Adjusted ShotQuality.
                          </Link>
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </section>
                  <div className="d-flex justify-content-center">
                    <Link
                      href={`/${defaultLeague}/team-standings`}
                      className="btn btn-secondary "
                      style={{ width: "auto" }}
                    >
                      See All {defaultLeague.toUpperCase()} Standings
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="premium">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {/* <span>Premium</span> */}
                <h2>Comprehensive {defaultLeague.toUpperCase()} Matchup Analyses</h2>
                <p>
                  Explore ShotQuality Metrics, Play-Type Styles, and Basketball Regression Stats. Dive into the most
                  in-depth {defaultLeague.toUpperCase()} basketball matchup analysis available.
                </p>
                <Link
                  href={`${defaultLeague}/matchup-tool`}
                  className="btn btn-secondary"
                  style={{ marginBottom: "0px" }}
                >
                  Try It Now
                </Link>
              </div>
              <div className="col-md-6  col-sm-12">
                <Image
                  src={tablesNew.src}
                  width={599}
                  height={100}
                  loading="lazy"
                  style={{ height: "max-content", width: "100%" }}
                  alt={`Deep Dive into ShotQuality Metrics, Play-Type Styles, and Regression Stats in the Most Detailed ${defaultLeague.toUpperCase()} Basketball Matchup Analysis`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mb-5 hidden">
            <div className="col-md-6 d-flex flex-column">
              <Image
                src={model.src}
                width={592}
                height={100}
                loading="lazy"
                style={{ height: "max-content" }}
                alt="Create Your Own Model feature"
              />
              <span className="tag">Premium</span>
              <h2>Create Your Own Model</h2>
              <p>
                Combine a custom array of shotquality’s industry leading statistics to find value and produce unique
                projections. Save, edit, track and share your system!{" "}
              </p>
              <Link href={`/model-sandbox`}>
                <button className="btn btn-secondary">Try It Now!</button>
              </Link>
            </div>
            <div className="col-md-6 d-flex flex-column">
              <Image
                src={trends.src}
                width={592}
                loading="lazy"
                height={100}
                style={{ height: "max-content" }}
                alt="Uncover Betting Trends feature"
              />
              <span className="tag">Premium</span>
              <h2>Uncover Betting Trends</h2>
              <p>
                Filter extensive game data to find the trends that should be winning using SQ Predictive data. See past
                occurrences, projected vs actual results, and identify future game instances by team, conference, road
                splits, and more!
              </p>
              <Link href={"/trend-finder"} className="btn btn-secondary">
                See More
              </Link>
            </div>
          </div>
          {/* contentful */}
          <ContentfulBlogs user={userDetails} />
        </div>
        <Testimonials />
        {/* {!isTrader && <PlanBlock />} */}

        <div className="container pt-5 mt-5">
          {/* <div className="row">
            <div className="col-md-12 d-flex flex-column">
              <h2 className="text-center">Expert Basketball Handicappers Love ShotQuality</h2>
              <div className="reviews">
                <div className="review">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex mb-3">
                      <Image
                        style={{ mixBlendMode: "multiply" }}
                        // src="https://pbs.twimg.com/profile_images/1577488904839733248/KhXYFYzc_400x400.jpg"
                        src="https://pbs.twimg.com/profile_images/1427790197455007744/rh5vkXid_400x400.jpg"
                        width={36}
                        height={36}
                        loading="lazy"
                        alt="Mike McClure, Sportsline"
                      />
                      <div className="d-flex flex-column">
                        <h3>Mike McClure, Sportsline</h3>
                        <a className="twitterLink" href="https://twitter.com/mike5754">
                          @Mike5754
                        </a>
                      </div>
                    </div>
                    {quoteSvg}
                  </div>
                  <p>
                    ShotQuality's predictive basketball data is some of the best in sports. Without ShotQuality live
                    betting is alot like flipping a coin.
                  </p>
                </div>
                <div className="review">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex mb-3">
                      <Image
                        style={{ mixBlendMode: "multiply" }}
                        src="https://pbs.twimg.com/profile_images/1427790197455007744/rh5vkXid_400x400.jpg"
                        width={36}
                        height={36}
                        loading="lazy"
                        alt="Jordan Majewski, Sports Writer"
                      />
                      <div className="d-flex flex-column">
                        <h3>Jordan Majewski, Sports Writer</h3>
                        <a className="twitterLink" href="https://twitter.com/jorcubsdan">
                          @jorcubsdan
                        </a>
                      </div>
                    </div>
                    {quoteSvg}
                  </div>
                  <p>ShotQuality delivers the most useful advanced metrics and analysis in basketball.</p>
                </div>
                <div className="review">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex mb-3">
                      <Image
                        style={{ mixBlendMode: "multiply" }}
                        src="https://pbs.twimg.com/profile_images/1502469232226971655/JJMuAY4N_400x400.jpg"
                        width={36}
                        height={36}
                        loading="lazy"
                        alt="Barstool Big Cat"
                      />
                      <div className="d-flex flex-column">
                        <h3> Barstool Big Cat</h3>
                        <a className="twitterLink" href="https://twitter.com/barstoolbigcat">
                          @BarstoolBigCat
                        </a>
                      </div>
                    </div>
                    {quoteSvg}
                  </div>
                  <p>
                    ShotQuality analysis highlights value on the board all season long, and you know I love the board.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-12 signUpBlock">
              <h2>
                Put ShotQuality basketball data to work to make <span style={{ color: "#F68F5A" }}>smarter</span> and
                more <span style={{ color: "#F68F5A" }}>accurate</span> picks and predictions.
              </h2>
              <div className="text-center">
                <Link
                  href={"/welcome"}
                  className="btn btn-secondary"
                  style={{ border: "2px solid #fff", marginRight: "30px" }}
                >
                  🔐 Unlock Access
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </MainLayout>
  );
};
export default HomePage;

export const getServerSideProps = appServerSideProps(async ({ appService }) => {
  const isUserLogged = appService.getUser();
  let userDetails = null;

  if (isUserLogged) {
    userDetails = await appService.get("api/account");
  }
  return { props: { userDetails } };
});
