import Link from "next/link";
import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";
import Utils from "../../utils/Utils";

const TableCellRank = ({
  leagueType = "",
  rankValue = "",
  children,
  props,
  conferenceTeamCount = 0,
  hideGradients = false,
  style = {},
  link = "",
  data_name = "",
}) => {
  const [gradientColor, setGradientColor] = useState("");
  let teamCount = 0;
  if (!conferenceTeamCount) {
    if (leagueType === "nba") {
      teamCount = 30;
    } else if (leagueType === "wnba") {
      teamCount = 12;
    } else if (leagueType === "college_mens" || leagueType === "ncaa") {
      teamCount = 358;
    } else if (leagueType === "college_womens" || leagueType === "ncaaw") {
      teamCount = 351;
    }
  }
  const count = conferenceTeamCount || teamCount;
  useEffect(() => {
    setGradientColor(
      Utils.getConfGradient({ rank: rankValue, conferenceTeamCount: count })
      // leagueType !== "conference"
      //   ? Utils.getGradientsForPercentile(typeof rankValue === "string" ? Number(rankValue.match(/\d+/)[0]) : rankValue)
      //   : Utils.getConfGradient({ rank: rankValue, conferenceTeamCount })
    );
  }, [leagueType, rankValue, conferenceTeamCount, count]);

  return link.length > 0 ? (
    <td data-name={data_name} className="position-relative RankCell" {...props} style={style}>
      <Link href={link} className="d-flex align-items-center justify-content-center gap-1">
        {children ? <span>{children}</span> : null}
        {hideGradients ? <small>{rankValue}</small> : <small style={{ background: gradientColor }}>{rankValue}</small>}
      </Link>
    </td>
  ) : (
    <td data-name={data_name} className="position-relative RankCell" {...props} style={style}>
      <div className="d-flex align-items-center justify-content-center gap-1">
        {children ? <span>{children}</span> : null}
        {hideGradients ? <small>{rankValue}</small> : <small style={{ background: gradientColor }}>{rankValue}</small>}
      </div>
    </td>
  );
};

export default TableCellRank;
